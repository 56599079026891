@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@font-face {
  font-family: "Parnaso";
  src: url("/src/ParnasoStandardTrialMedium.otf") format(opentype);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Parnaso";
  src: url("/src/ParnasoStandardTrialRegular.otf") format(opentype);
  font-style: normal;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
.h4,
blockquote {
  font-family: "Parnaso";
  font-weight: 500;
  letter-spacing: -2px;
}

h1 {
  font-size: 3rem;
  line-height: 1.1em;
}

.h1-light {
  font-size: 3rem;
  line-height: 1.1em;
  font-family: "Parnaso";
  font-weight: 400;
  letter-spacing: -2px;
}

h2 {
  font-size: 2.5rem;
}

h3,
blockquote {
  font-size: 2rem;
}

h2,
h3,
blockquote {
  line-height: 1.2;
}

h4,
.h4 {
  font-size: 1.5rem;
}

.post-block {
  p {
    letter-spacing: -0.15px;
  }
}

b {
  font-weight: 600;
}

sup {
  font-size: 0.75em;
}

.underline {
  padding-bottom: 1px;
  border-bottom: solid white 1px;
}

.overline {
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
}

.post-impact {
  a {
    color: white;
  }

  a:visited {
    color: white;
  }

  a:hover,
  a:focus {
    color: #b3b3b3;
  }
}

small {
  font-style: italic;
}
