@import "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap";
@font-face {
  font-family: Parnaso;
  font-style: normal;
  font-weight: 500;
  src: url("ParnasoStandardTrialMedium.34652488.otf") format("opentype");
}

@font-face {
  font-family: Parnaso;
  font-style: normal;
  font-weight: 400;
  src: url("ParnasoStandardTrialRegular.df4558de.otf") format("opentype");
}

.h4, blockquote, h1, h2, h3, h4 {
  letter-spacing: -2px;
  font-family: Parnaso;
  font-weight: 500;
}

.h1-light, h1 {
  font-size: 3rem;
  line-height: 1.1em;
}

.h1-light {
  letter-spacing: -2px;
  font-family: Parnaso;
  font-weight: 400;
}

h2 {
  font-size: 2.5rem;
}

blockquote, h3 {
  font-size: 2rem;
}

blockquote, h2, h3 {
  line-height: 1.2;
}

.h4, h4 {
  font-size: 1.5rem;
}

.post-block p {
  letter-spacing: -.15px;
}

b {
  font-weight: 600;
}

sup {
  font-size: .75em;
}

.underline {
  border-bottom: 1px solid #fff;
  padding-bottom: 1px;
}

.overline {
  letter-spacing: .5px;
  text-transform: uppercase;
  font-size: .75rem;
}

.post-impact a, .post-impact a:visited {
  color: #fff;
}

.post-impact a:focus, .post-impact a:hover {
  color: #b3b3b3;
}

small {
  font-style: italic;
}

*, :after, :before {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

canvas, img, picture, svg, video {
  max-width: 100%;
  display: block;
}

button, input, select, textarea {
  font: inherit;
}

h1, h2, h3, h4, h5, h6, p {
  word-wrap: break-word;
}

#__next, #root {
  isolation: isolate;
}

.home--grid-two-column, .home-about, .home-intro, .home-work .home-work--grid {
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  display: grid;
}

@media (width <= 968px) {
  .home--grid-two-column, .home-about, .home-intro, .home-work .home-work--grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

.home--container {
  padding: 3rem 3.5rem;
}

@media (width <= 968px) {
  .home--container {
    padding: 3rem 2rem;
  }
}

@media (width <= 480px) {
  .home--container {
    padding: 3rem 1rem;
  }
}

.home--container-xl {
  padding: 4rem 3.5rem 5.5rem;
}

@media (width <= 968px) {
  .home--container-xl {
    padding: 3rem 2rem;
  }
}

@media (width <= 480px) {
  .home--container-xl {
    padding: 3rem 1rem;
  }
}

.home-about {
  color: #fff;
  background-color: #101010;
}

.home-about .home-about--item {
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  display: flex;
}

.home-about .home-about--info {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.home-carousel {
  background-color: #101010;
  align-items: center;
  height: 100%;
  padding-top: 1rem;
  display: flex;
  overflow: hidden;
}

.home-carousel .carousel-track {
  will-change-pink: transform;
  gap: 1rem;
  animation: linear infinite scroll;
  display: flex;
}

.home-carousel .carousel-track:hover {
  animation-play-state: paused;
}

.home-carousel span {
  color: #fff;
  white-space: nowrap;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

.home-work {
  background-color: #fff;
  background-color: var(--hover-bg-color, #fff);
  transition: background-color .7s;
}

.home-work, .home-work .home-work--grid-item {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

.home-work .home-work--grid-item {
  cursor: pointer;
  transition: transform .3s;
}

@media (hover: hover) and (pointer: fine) {
  .home-work .home-work--grid-item:hover {
    transform: translateY(-5px);
  }
}

.home-work .home-work--grid-item .article-info {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.home-work .home-work--grid-item .main-link {
  color: #000;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.home-intro {
  color: #fff;
  background-color: #101010;
  align-items: center;
}

.home-intro h1 {
  color: #fff;
}

.home-intro .home-intro--info {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

header {
  z-index: 1000;
  background-color: #101010;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 3.5rem;
  font-size: 16px;
  display: flex;
  position: sticky;
  top: 0;
}

@media (width <= 968px) {
  header {
    padding: 1rem 2rem;
  }
}

@media (width <= 480px) {
  header {
    padding: 1rem;
  }
}

.header__link, .header__name {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: color .3s;
}

@media (width <= 480px) {
  .header__link, .header__name {
    font-size: 14px !important;
  }
}

.header__nav {
  gap: 1rem;
  display: flex;
}

@media (width <= 480px) {
  .header__nav {
    gap: .5rem;
  }
}

.header__link:hover, .header__name:hover {
  color: #b3b3b3;
}

footer {
  background-color: #fafafa;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem 3.5rem;
  display: flex;
}

footer a {
  letter-spacing: -.5px;
  font-family: Parnaso;
  font-size: 24px;
  font-weight: 500;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: color .3s;
}

footer a, footer a:visited {
  color: #000;
}

footer a:focus, footer a:hover {
  color: #4d4d4d;
}

footer .footer--copy {
  width: 50%;
}

footer .footer--contacts {
  flex-direction: row;
  gap: 1rem;
  display: flex;
}

footer .footer--info .overline {
  margin-bottom: .5rem;
}

@media (width <= 968px) {
  footer {
    padding: 3rem 2rem;
  }

  footer .footer--copy {
    width: auto;
  }
}

@media (width <= 480px) {
  footer {
    padding: 3rem 1rem;
  }

  footer .footer--contacts {
    flex-direction: column;
  }
}

.button-link {
  color: #000;
  text-transform: uppercase;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  width: max-content;
  padding: .5rem;
  font-size: 16px;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: background-color .3s;
  display: inline-block;
}

.button-link:hover {
  color: #fff;
  background-color: #4d4d4d;
}

.button-link--intro {
  color: #fff;
  text-transform: uppercase;
  background-color: #000;
  border: none;
  border-radius: 4px;
  width: max-content;
  padding: .5rem;
  font-size: 16px;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: background-color .3s, color .3s;
  display: inline-block;
}

.button-link--intro:hover {
  color: #fff;
  background-color: #4d4d4d;
}

.button-link--white {
  text-transform: uppercase;
  background-color: #fff;
  border-radius: 4px;
  width: max-content;
  padding: .5rem;
  font-size: 16px;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: background-color .3s, color .3s;
  display: inline-block;
  color: #000 !important;
}

.button-link--white:hover {
  background-color: #4d4d4d;
  color: #fff !important;
}

.btn-group {
  flex-flow: wrap;
  gap: 1rem;
  display: flex;
}

.role-card .role-card__title {
  letter-spacing: .04em;
  text-transform: uppercase;
}

.role-card {
  border-radius: 4px;
  flex-direction: row;
  align-items: flex-start;
  gap: .75rem;
  width: 388px;
  padding: .75rem 1rem .75rem .75rem;
  display: flex;
}

.role-card .role-card__avatar {
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 1.25rem;
  display: flex;
}

.role-card .role-card__title {
  margin-bottom: .25rem;
  font-size: .75rem;
  font-weight: 600;
}

.role-card .role-card__description {
  font-size: 1rem;
  line-height: 1.25;
}

.role-card .role-card__description, .role-card .role-card__title {
  color: #000;
}

@media (width <= 968px) {
  .role-card {
    width: 100%;
  }
}

.journal-manager__style .role-card {
  background-color: #fff5fb;
}

.journal-manager__style .role-card .role-card__avatar {
  background-color: #ffdfe9;
}

@keyframes glisten {
  0% {
    background-position: -200% 0;
  }

  50% {
    background-position: 100% 0;
  }

  to {
    background-position: -200% 0;
  }
}

.context-card, .context-card--hover {
  color: #000;
  letter-spacing: 0;
  border: 1px solid #dedede;
  border-radius: 4px;
  flex-direction: row;
  align-items: flex-start;
  gap: .75rem;
  padding: 1rem 1.25rem 1rem 1rem;
  font-family: Inter, Arial, sans-serif;
  font-size: 18px;
  display: flex;
}

.context-card {
  background: #f8f7f7;
}

.context-card--hover {
  opacity: 0;
  will-change: transform;
  z-index: 10;
  background: #fff;
  width: 508px;
  line-height: 1.5;
  transition: opacity .3s .1s, transform .3s .1s;
  display: none;
  position: absolute;
  bottom: calc(100% + 8px);
  left: 0;
  transform: translateY(10px);
}

.context-card--hover.visible {
  opacity: 1;
  display: flex;
  transform: translateY(0);
}

@media (width <= 968px) {
  .context-card--hover {
    width: auto;
    top: calc(100% + 16px);
    bottom: auto;
    left: 0;
  }
}

.context-card--avatar {
  font-size: 1.25rem;
}

@media (width <= 968px) {
  .context-card--avatar {
    cursor: pointer;
  }
}

.journal-manager__style .context-card {
  background: linear-gradient(120deg, #fff5fb, #fffafd 50%, #fff5fb) 0 0 / 200% 100%;
  border: 1px solid #f4b4c8;
  animation: 7s ease-in-out infinite glisten;
}

.context-badge {
  cursor: pointer;
  vertical-align: middle;
  background: #ffffff80;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
  margin: 0 .125rem;
  padding: .2rem .4rem;
  line-height: 1;
  display: inline-flex;
  position: relative;
}

@media (width <= 968px) {
  .context-badge {
    padding: 0 .4rem;
  }
}

.context-badge--icon {
  font-size: 2rem;
  line-height: 1;
}

@media (width <= 968px) {
  .context-badge--icon {
    font-size: 1.5rem;
  }
}

.context-badge--text {
  letter-spacing: .01em;
  text-transform: uppercase;
  font-family: Inter;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: 3rem;
}

@media (width <= 968px) {
  .context-badge--text {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.stat {
  flex-direction: column;
  gap: .75rem;
  width: 100%;
  display: flex;
}

@media (width <= 968px) {
  .stat {
    gap: 0;
  }
}

@media (width <= 480px) {
  .stat {
    width: 100%;
  }
}

.stat__description {
  background: #f8f7f7;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  display: flex;
}

@media (width <= 968px) {
  .stat__description {
    border-radius: 4px 4px 0 0;
  }
}

.stat__description--icon, .stat__description--title {
  font-size: 1.5rem;
}

.stat__description--title {
  font-weight: 600;
  line-height: 1.1;
}

@media (width <= 968px) {
  .stat__description--title {
    font-size: 1.25rem;
  }
}

.stat__number {
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-end;
  gap: .5rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
}

.stat__number strong {
  font-size: 2.25rem;
  font-weight: 600;
}

@media (width <= 968px) {
  .stat__number {
    border-radius: 0 0 4px 4px;
  }

  .stat__number strong {
    font-size: 1.5rem;
  }
}

.journal-manager__style .stat__number {
  background-color: #ffdfe9;
}

.article__outcomes .grid--stats {
  margin: 1rem 0;
}

@media (width <= 968px) {
  .article__outcomes .grid--stats {
    margin: initial;
  }
}

.container-m {
  padding: 0 16rem;
}

@media (width <= 968px) {
  .container-m {
    padding: 0 2rem;
  }
}

@media (width <= 480px) {
  .container-m {
    padding: 0 1rem;
  }
}

.post, .post-impact, .post-intro {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.post {
  gap: 3rem;
}

@media (width <= 968px) {
  .post {
    gap: 2rem;
  }
}

.post img {
  margin: 1rem 0;
}

.post .img-full-w {
  margin: 2rem 0;
}

@media (width <= 968px) {
  .post .img-full-w {
    margin: 1rem 0;
  }
}

.post .img-left img {
  margin-top: 0;
}

.post-intro {
  background-color: #101010;
  align-items: center;
  gap: 0;
  padding: 5rem 12rem;
}

.post-intro h1, .post-intro p {
  color: #fff;
  text-align: center;
}

.post-intro h1 {
  margin: 1rem 0;
}

@media (width <= 968px) {
  .post-intro {
    padding: 3rem 6rem;
  }
}

@media (width <= 480px) {
  .post-intro {
    align-items: flex-start;
    padding: 2rem 1rem;
  }

  .post-intro h1, .post-intro p {
    text-align: left;
    text-align: initial;
  }
}

.post-intro-img {
  background-color: #101010;
  align-items: center;
  padding: 0 12rem 5rem;
  display: flex;
}

@media (width <= 968px) {
  .post-intro-img {
    padding: 0;
  }
}

.post-intro-img img {
  margin: 0;
}

.post-impact {
  background-color: #101010;
  padding: 5rem 16rem;
}

.post-impact h2, .post-impact h3, .post-impact p, .post-impact small, .post-impact sup, .post-impact ul {
  color: #fff;
}

.post-impact .stat-context {
  margin-top: 2rem;
}

@media (width <= 968px) {
  .post-impact .stat-context {
    margin-top: 1rem;
  }

  .post-impact {
    padding: 3rem 2rem;
  }
}

@media (width <= 480px) {
  .post-impact {
    padding: 3rem 1rem;
  }
}

.team-shout {
  background-color: #101010;
  padding-bottom: 3rem;
}

.team-shout a, .team-shout h2, .team-shout h3, .team-shout p, .team-shout small {
  color: #fff;
}

.has-conclusion {
  gap: 4rem;
}

.has-conclusion .stat-container {
  margin: 2rem 0 0;
}

@media (width <= 968px) {
  .has-conclusion {
    gap: 2rem;
  }
}

.overview__descript h2:first-of-type, .overview__descript h3, .post-block h2:first-of-type, .post-block h3, .post-block p, .post-glance h2:first-of-type, .post-glance h3, .post-highlight h2:first-of-type, .post-highlight h3, .post-highlight p, .post-impact h2:first-of-type, .post-impact h3, .post-impact p, .scroll-item h2:first-of-type, .scroll-item h3, .team-shout h2:first-of-type, .team-shout h3 {
  margin-bottom: .5rem;
}

.post-block ol, .post-block ul, .post-highlight ol, .post-highlight ul, .post-impact ol, .post-impact ul {
  margin: .5rem 0 1rem;
}

.overview {
  gap: 2.5rem;
  margin-bottom: 2rem;
  padding: 3rem 5rem;
}

.overview .overview__descript {
  flex-direction: column;
  flex: 0 0 65%;
  gap: 1.5rem;
  display: flex;
}

.overview .overview__descript p + p {
  margin-top: .5rem;
}

.overview .overview__info {
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding: 2rem;
  display: flex;
}

@media (width <= 968px) {
  .overview .overview__info {
    border-top: 1px solid #101010;
    padding: 2rem 0 0;
    background-color: #0000 !important;
    background-color: initial !important;
  }

  .overview {
    flex: none;
    width: 100%;
    margin-bottom: 1rem;
    padding: 2.5rem 2rem;
  }
}

@media (width <= 480px) {
  .overview {
    padding: 2.5rem 1rem;
  }
}

.post-glance {
  margin-bottom: 1rem;
}

.post-glance .row {
  border-bottom: 2px solid #ececec;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0;
  display: flex;
}

.post-glance .row p:first-of-type {
  flex: 0 0 25%;
}

.post-glance .row p:last-of-type {
  flex: 1;
}

@media (width <= 968px) {
  .post-glance .row {
    flex-direction: column;
    align-items: flex-start;
  }

  .post-glance .row p:first-of-type {
    flex: 1;
  }
}

.post-glance .row:last-of-type {
  border-bottom: initial;
}

.post-highlight {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4vh 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width <= 968px) {
  .post-highlight {
    align-items: normal;
    margin: 0;
  }
}

.img-left {
  align-items: center;
  gap: 2rem;
  padding-right: 5rem;
}

.img-left img {
  -o-object-fit: contain;
  object-fit: contain;
  flex: 0 0 60%;
  max-width: 60%;
  height: auto;
}

@media (width <= 968px) {
  .img-left img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    flex: none !important;
  }
}

.img-left .wrapper {
  flex: 0 0 40%;
  max-width: 40%;
}

@media (width <= 968px) {
  .img-left .wrapper {
    flex: 1;
    max-width: 100%;
    padding: 0 2rem;
  }
}

@media (width <= 480px) {
  .img-left .wrapper {
    padding: 0 1rem;
  }
}

@media (width <= 968px) {
  .img-left {
    flex-direction: column;
    padding-right: 0;
  }
}

.quote {
  flex-direction: column;
  align-items: center;
  margin: 3rem 3rem 0;
  display: flex;
}

.quote p {
  margin-top: .25rem;
}

@media (width <= 968px) {
  .quote {
    margin: 2rem 0 0;
  }
}

.img-columns {
  align-items: start;
  gap: 2rem;
  padding: 0 5rem;
}

.img-columns .wrapper {
  flex: 1;
}

@media (width <= 968px) {
  .img-columns {
    flex-direction: column;
    padding: 0 2rem;
  }
}

@media (width <= 480px) {
  .img-columns {
    padding: 0 1rem;
  }
}

.next-steps {
  margin: 4rem 0;
}

@media (width <= 968px) {
  .next-steps {
    margin: 2rem 0;
  }
}

@media (width <= 480px) {
  .next-steps {
    margin: 1rem 0;
  }

  .next-steps h1 {
    padding: 0 !important;
  }
}

.scroll-section h2 {
  text-align: center;
}

.scroll-section .scroll-container {
  align-items: flex-start;
  gap: 2rem;
  margin-left: 3rem;
  padding: 1rem 3rem 1rem 0;
  display: flex;
  overflow: auto hidden;
}

.scroll-section .scroll-item {
  box-sizing: border-box;
  flex-direction: column;
  flex: none;
  justify-content: center;
  min-width: 60%;
  max-width: 700px;
  display: flex;
}

.scroll-section .scroll-item img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: auto;
}

@media (width <= 968px) {
  .scroll-section {
    padding: 0 2rem;
  }

  .scroll-section h2 {
    text-align: left;
    text-align: initial;
  }

  .scroll-section .scroll-container {
    padding: initial;
    flex-direction: column;
    margin-left: 0;
    overflow: hidden auto;
  }

  .scroll-section .scroll-item {
    width: 100%;
    min-width: auto;
    max-width: none;
  }
}

@media (width <= 480px) {
  .scroll-section {
    padding: 0 1rem;
  }
}

.container--block {
  padding: 5rem 3.5rem;
}

@media (width <= 968px) {
  .container--block {
    padding: 3rem 2rem;
  }
}

@media (width <= 480px) {
  .container--block {
    padding: 3rem 1rem;
  }
}

.container--block-vertical {
  padding: 5rem 3.5rem;
}

@media (width <= 968px) {
  .container--block-vertical {
    padding: 3rem 2rem;
  }
}

@media (width <= 480px) {
  .container--block-vertical {
    padding: 3rem 1rem;
  }
}

.grid--simple {
  grid-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  padding: 1rem 0 0;
  display: grid;
}

@media (width <= 968px) {
  .grid--simple {
    grid-template-columns: 1fr;
    padding: .5rem 0 0;
  }
}

.grid--stats {
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
}

.grid--stats + .disclaimer {
  margin-top: 0;
}

@media (width <= 680px) {
  .grid--stats {
    grid-template-columns: 1fr;
  }

  .grid--stats + .disclaimer {
    margin-top: 1rem;
  }
}

.article__goal, .disclaimer {
  flex-direction: column;
  display: flex;
}

.article__goal {
  gap: 2rem;
}

.article__goal .article__goal--title {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
}

.article__goal .h1-light {
  line-height: 1.4;
}

@media (width <= 968px) {
  .article__goal .h1-light {
    font-size: 2.5rem;
    line-height: 1.25;
  }

  .article__key-question .key-question {
    font-size: 2.5rem;
  }
}

.article__discovery {
  flex-direction: row;
  display: flex;
}

.article__discovery .article__discovery--img, .article__discovery .article__discovery--info {
  width: 50%;
}

.article__discovery .article__discovery--info {
  background: linear-gradient(#fff 32.5%, #fff5fb);
  flex-direction: column;
  gap: 5rem;
  display: flex;
}

.article__discovery .article__discovery--img {
  background-image: url("jm_discovery-vertical.37dee05d.png");
  background-position: 50%;
  background-size: cover;
  height: 100vh;
  position: sticky;
  top: 0;
}

@media (width <= 968px) {
  .article__discovery {
    flex-direction: column;
  }

  .article__discovery .article__discovery--img, .article__discovery .article__discovery--info {
    width: 100%;
  }

  .article__discovery .article__discovery--info {
    gap: 3rem;
  }

  .article__discovery .article__discovery--img {
    height: 40vh;
  }
}

.article__header-img {
  background-image: url("jm_intro.f400f64d.png");
  background-position: 50%;
  background-size: cover;
  height: 40vh;
}

@media (width <= 968px) {
  .article__header-img {
    height: 24vh;
  }
}

@media (width <= 480px) {
  .article__header-img {
    height: 20vh;
  }
}

.article__conclusion, .article__header {
  background-color: #101010;
  gap: 3rem;
  display: flex;
}

.article__conclusion a, .article__conclusion figcaption, .article__conclusion h1, .article__conclusion h2, .article__conclusion h3, .article__conclusion h4, .article__conclusion p, .article__conclusion strong, .article__header a, .article__header figcaption, .article__header h1, .article__header h2, .article__header h3, .article__header h4, .article__header p, .article__header strong {
  color: #fff;
}

.article__header {
  flex-direction: row;
  align-items: center;
}

.article__header .article__header--role-info {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.article__header .button-link--white {
  margin-top: 1rem;
}

@media (width <= 968px) {
  .article__header .button-link--white {
    margin-top: .5rem;
  }

  .article__header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
}

.article__conclusion {
  flex-direction: row;
}

.article__conclusion .article__conclusion--info {
  flex: 0 0 40%;
  height: 100vh;
  position: sticky;
  top: 6rem;
}

@media (width <= 968px) {
  .article__conclusion .article__conclusion--info {
    height: auto;
    position: static;
    top: auto;
  }
}

.article__conclusion .article__conclusion--credits {
  flex-direction: column;
  flex: 1;
  gap: 3rem;
  display: flex;
}

.article__conclusion .article__conclusion--impact-wrapper strong {
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
}

.article__conclusion .article__conclusion--wrapper img {
  border-radius: 4px;
  margin-top: 2rem;
}

@media (width <= 968px) {
  .article__conclusion {
    flex-direction: column;
    align-items: flex-start;
  }
}

.article__solutions--grid {
  gap: 4rem;
}

@media (width <= 968px) {
  .article__solutions--grid {
    gap: 2.25rem;
  }
}

.highlight-item img {
  border: 4px solid #fff;
  border-radius: 4px;
}

.text-block-spacing-m {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.text-block-spacing-l {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

.text-block-spacing-xl {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.text-block-spacing-xxl {
  flex-direction: column;
  gap: 3.5rem;
  display: flex;
}

@media (width <= 968px) {
  .text-block-spacing-xxl {
    flex-direction: column;
    gap: 2.5rem;
    display: flex;
  }
}

.less-width {
  width: 80%;
}

@media (width <= 480px) {
  .less-width {
    width: auto;
  }
}

.img-trim {
  border-radius: 4px;
}

.will-change-pink {
  transition: background-color .3s ease-in;
}

.pink-bg, .will-change-pink {
  background-color: #ffdfe9;
}

.light-pink-bg {
  background-color: #fff5fb;
}

.fade-in {
  opacity: 0;
  transition: opacity .6s ease-out, transform .6s ease-out;
  transform: translateY(20px);
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

body {
  background-color: #fff;
  font-family: Inter, Arial, sans-serif;
  font-size: 18px;
}

body h1 {
  color: #000;
}

.flex-row {
  flex-direction: row;
  display: flex;
}

@media (width <= 968px) {
  .flex-row {
    flex-direction: column;
  }
}

.text-center {
  text-align: center;
}

@media (width <= 968px) {
  .text-center {
    text-align: left;
    text-align: initial;
  }
}

.remove-m-top {
  margin-top: 0;
}

.add-m-top-headers {
  margin-top: 1.5rem;
}

.stat-container {
  flex: 1;
  gap: 2rem;
  margin: 4rem 0;
}

@media (width <= 968px) {
  .stat-container {
    margin: 2rem 0;
  }
}

.stat-container .stats {
  width: 100%;
}

.stat-container .stat-number {
  font-size: 2rem;
}

.stat-container .stat-descript {
  text-wrap: wrap;
}
/*# sourceMappingURL=index.367b5b1f.css.map */
